import api from "./api";

export const QuestionarioService = {
    buscarDadosQuestionario: (hash: string) => {
        return api.get(`/questionario`, {
            headers: {
                Authorization: hash,
            },
        });
    },
    storeRespostasQuestionario: (
        hash: string,
        payload: any
    ) => {
        return api.post(
            `/questionario/resposta`,
            { respostas: payload },
            {
                headers: {
                    Authorization: hash,
                },
            }
        );
    },
};
