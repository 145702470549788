import React, { useContext, useMemo } from "react";
import "./index.css"
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";

import Select from "react-select";
import { MultiSelect, Option } from "react-multi-select-component";
import { PerguntaQuestionarioType, TipoRespostaEnum } from "../../types/questionario";
import { celularMask, cepMask, cpfMask, realMoeda } from "../../../helpers/Mascaras";
import { QuestionarioContext } from "../../../context/QuestionarioContext/QuestionarioContext";
import { TipoCampoDinamicoAPI } from "../../types/oportunidadeTypes";

interface PerguntaItemProps {
  pergunta: PerguntaQuestionarioType;
  index: number;
}

export function PerguntaItem({
  pergunta, index
}: PerguntaItemProps) {
  const methods = useFormContext();
  const { tipologias, localidades, comoConheceu, empreendimentos } = useContext(QuestionarioContext);

  const configs = useMemo(() => {
    return {
      selectSomeItems: "Selecione",
      allItemsAreSelected: "Todos itens selecionados",
      selectAll: "Selecionar todos",
      search: "Pesquisar",
    };
  }, []);

  const nameCampo = useMemo(() => {
    return pergunta.id_pergunta.toString();
  }, [pergunta]);

  const createOptionsCamposDinamicos = (tipo: number) => {
    switch (tipo) {
      case TipoCampoDinamicoAPI.COMO_CONHECEU:
        return comoConheceu.map((comoConheceu) => {
          return {
            label: comoConheceu.descricao,
            value: comoConheceu.id_como_conheceu,
          };
        });
      case TipoCampoDinamicoAPI.LOCALIZACAO:
        return localidades.map((localidade, index) => {
          return {
            label: localidade,
            value: localidade,
          };
        });
      case TipoCampoDinamicoAPI.TIPOLOGIA:
        return tipologias.map((tipologia, index) => {
          return {
            label: tipologia.descricao,
            value: tipologia.id,
          };
        });
      case TipoCampoDinamicoAPI.EMPREENDIMENTO:
        return empreendimentos.map((empreendimento, index) => {
          return {
            label: empreendimento.nome,
            value: empreendimento.id,
          };
        });
      default:
        return [];
    }
  };

  const resolverCampo = () => {
    var options: any[] = [];
    switch (pergunta.pergunta.id_tipo_resposta_pergunta) {
      case TipoRespostaEnum.CEP:
        return (
          <Form.Control
            type="text"
            {...methods.register(nameCampo, {required: pergunta?.obrigatorio})}
            placeholder="Digite o CEP..."
            onChange={(event) => {
              methods.setValue(nameCampo, cepMask(event.target.value));
            }}
          />
        );
      case TipoRespostaEnum.CPF:
        return (
          <Form.Control
            type="text"
            {...methods.register(nameCampo, {required: pergunta?.obrigatorio})}
            placeholder="Digite o CPF..."
            onChange={(event) => {
              methods.setValue(nameCampo, cpfMask(event.target.value));
            }}
          />
        );
      case TipoRespostaEnum.TELEFONE:
        return (
          <Form.Control
            type="text"
            {...methods.register(nameCampo, {required: pergunta?.obrigatorio})}
            placeholder="Digite o telefone..."
            onChange={(event) => {
              methods.setValue(nameCampo, celularMask(event.target.value));
            }}
          />
        );
      case TipoRespostaEnum.TEXTO:
        return (
          <Form.Control
            type="text"
            {...methods.register(nameCampo, {required: pergunta?.obrigatorio})}
            placeholder="Digite ..."
          />
        );
      case TipoRespostaEnum.DATA:
        return (
          <Form.Control
            type="date"
            {...methods.register(nameCampo, {required: pergunta?.obrigatorio})}
          />
        );
      case TipoRespostaEnum.MOEDA:
        return (
          <Form.Control
            type="text"
            {...methods.register(nameCampo, {required: pergunta?.obrigatorio})}
            placeholder="R$ 0,00"
            onChange={(event) => {
              methods.setValue(nameCampo, `R$ ${realMoeda(event.target.value)}`);
            }}
          />
        );
      case TipoRespostaEnum.SIMPLES_SELECAO:
      case TipoRespostaEnum.SIMPLES_SELECAO_CAMPOS_DINAMICOS:
        if (Array.isArray(pergunta.pergunta.options)) {
          options = pergunta.pergunta.options;
        } else {
          options = createOptionsCamposDinamicos(
            parseInt(pergunta.pergunta?.options || "0")
          ) as any[];
        }
        return (
          <Controller
            control={methods.control}
            name={nameCampo}
            defaultValue={[]}
            rules={{required: pergunta?.obrigatorio}}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Select
                placeholder="Selecione"
                options={(options as Option[]) || []}
                className="basic-multi-select"
                onChange={onChange}
              />
            )}
          />
        );
      case TipoRespostaEnum.MULTIPLA_SELECAO:
      case TipoRespostaEnum.MULTIPLA_SELECAO_CAMPOS_DINAMICOS:
        if (Array.isArray(pergunta.pergunta.options)) {
          options = pergunta.pergunta.options;
        } else {
          options = createOptionsCamposDinamicos(
            parseInt(pergunta.pergunta?.options || "0")
          ) as any[];
        }
        return (
          <Controller
            control={methods.control}
            name={nameCampo}
            defaultValue={[]}
            rules={{required: pergunta?.obrigatorio}}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <MultiSelect
                options={(options as Option[]) ?? []}
                labelledBy=""
                value={value}
                overrideStrings={configs}
                onChange={onChange}
              />
            )}
          />
        );
      case TipoRespostaEnum.CHECKBOX:
        return (
          <div className="resposta-checkbox-section">
            <Controller
              control={methods.control}
              name={nameCampo}
              defaultValue={[]}              
              key={nameCampo}
              rules={{required: pergunta?.obrigatorio}}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <>
                  {pergunta.pergunta?.options?.map(
                    (item: any, index: number) => (
                      <div key={index}>
                        <input
                          {...methods.register(nameCampo)}
                          id={`${pergunta.id_questionario}-${nameCampo}-${index}`}
                          value={item.value}
                          type="checkbox"
                        />
                        <label
                          style={{ cursor: "pointer", marginBottom: "0px" }}
                          htmlFor={`${pergunta.id_questionario}-${nameCampo}-${index}`}
                        >
                          {item.value}
                        </label>
                      </div>
                    )
                  )}
                </>
              )}
            />
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="pergunta-opv-item">
      <div className="enunciado-section">
        <span className="number">
          {`${(index + 1).toString().padStart(2, '0')}. `}
        </span>
        <span className="enunciado">
          {pergunta.pergunta.enunciado}
          <span className="enunciado-obrigatorio">*</span>
        </span>
      </div>
      {resolverCampo()}
      {methods.formState.errors[nameCampo] && methods.formState.errors[nameCampo] && (
        <small className="form-text text-danger">Campo Obrigatório*</small>
      )}
    </div>
  );
}
