import React from "react";
import "./index.css";
import closed from "../../assets/images/close.png";

export function LinkExpirado(){
    return (
        <div className="link-expirado-section">
            <img src={closed} alt="closed"/>
            <div className="text-section">
                <span>Link Expirado !!!</span>
                <span>Favor solicitar um novo!!</span>
            </div>
        </div>
    )
}