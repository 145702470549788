import React, { createContext, Dispatch, ReactElement, useState } from "react";
import Swal from "sweetalert2";
import { QuestionarioType, RespondidoType } from "../../components/types/questionario";
import { QuestionarioService } from "../../services/QuestionarioService";
import { ComoConheceuType, EmpreendimentoFotoType, EmpreendimentoType, EmpreendimentoVendasType, TipologiaType } from "../../components/types/oportunidadeTypes";
import { trackPromise } from "react-promise-tracker";

interface QuestionarioContextProps {
  hash: string;
  questionario: QuestionarioType;
  dataUltimaResposta: RespondidoType;
  setDataUltimaResposta: Dispatch<React.SetStateAction<RespondidoType>>;
  empreendimento: EmpreendimentoType;
  fotosEmpreendimento: EmpreendimentoFotoType[];
  tipologias: TipologiaType[];
  localidades: string[];
  comoConheceu: ComoConheceuType[];
  empreendimentos: EmpreendimentoVendasType[];
  buscarDadosQuestionario: (hash: string, payload: any) => void;
  linkExpirado: boolean;
  oportunidade: number;
  idQuestionarioPublico?: number;
  setLinkExpirado: React.Dispatch<React.SetStateAction<boolean>>;
  setQuestionario: React.Dispatch<React.SetStateAction<QuestionarioType>>;
}

export const QuestionarioContext = createContext({} as QuestionarioContextProps);

export function QuestionarioProvider({ children }: { children: ReactElement }) {
  const [hash, setHash] = useState("");
  const [idQuestionarioPublico, setIdQuestionarioPublico] = useState<number>();
  const [questionario, setQuestionario] = useState<QuestionarioType>({} as QuestionarioType);
  const [dataUltimaResposta, setDataUltimaResposta] = useState<RespondidoType>({} as RespondidoType);
  const [empreendimento, setEmpreendimento] = useState<EmpreendimentoType>({} as EmpreendimentoType);
  const [fotosEmpreendimento, setFotosEmpreendimento] = useState<EmpreendimentoFotoType[]>([]);
  const [tipologias, setTipologias] = useState<TipologiaType[]>([]);
  const [localidades, setLocalidades] = useState<string[]>([]);
  const [comoConheceu, setComoConheceu] = useState<ComoConheceuType[]>([]);
  const [empreendimentos, setEmpreendimentos] = useState<EmpreendimentoVendasType[]>([]);
  const [linkExpirado, setLinkExpirado] = useState(false);
  const [oportunidade, setOportunidade] = useState(0);

  const buscarDadosQuestionario = (hash: string, payload=null) => {
    trackPromise(QuestionarioService.buscarDadosQuestionario(hash, payload)).then((response: any) => {
      setHash(hash);
      setIdQuestionarioPublico(response?.data?.id_questionario_publico);
      setQuestionario(response?.data?.questionario);
      setDataUltimaResposta({
        respondido: response?.data?.ja_respondido,
        dataResposta: response?.data?.data_ultima_resposta
      });
      setEmpreendimento(response?.data?.empreendimento);
      setFotosEmpreendimento(response?.data?.fotos_empreendimento);
      setTipologias(response?.data?.tipologias);
      setLocalidades(response?.data?.localidades);
      setComoConheceu(response?.data?.como_conheceu);
      setEmpreendimentos(response?.data?.empreendimentos);
      setOportunidade(response?.data?.oportunidade);

    }).catch((error) => {
        setHash(hash);
        if (typeof error?.response?.data?.mensagem === 'string') {
          if (error?.response?.data?.mensagem?.includes("Formulário já respondido")) {
            setDataUltimaResposta({
              respondido: true,
              dataResposta: " "
            }
            )
          } else if (error?.response?.data?.mensagem.includes("Formulário expirado")) {
            setLinkExpirado(true);
          }
        } else {
          Swal.fire({
            titleText: "Ooops...",
            text: error?.response?.data?.message ?? ("Ocorreu um erro ao tentar salvar as respostas: " + error),
            icon: "error",
          });

          if(error?.response?.data?.message?.includes("Expirado")){
            setLinkExpirado(true);
          }
        }
    })
  }

  return (
    <QuestionarioContext.Provider value={{
      hash,
      questionario,
      buscarDadosQuestionario,
      dataUltimaResposta,
      setDataUltimaResposta,
      empreendimento,
      empreendimentos,
      fotosEmpreendimento,
      tipologias,
      localidades,
      comoConheceu,
      linkExpirado,
      setLinkExpirado,
      oportunidade,
      idQuestionarioPublico,
      setQuestionario,
    }}>
      {children}
    </QuestionarioContext.Provider>
  );
}
