import { LeadOportunidadeType } from "./leadTypes";

export type OportunidadeType = {
    id_oportunidade: number;
    id_empreendimento: number;
    id_equipe_pre_atendimento: number;
    id_gerente_pre_atendimento: number;
    id_usuario_pre_atendimento: number;
    id_equipe_pdv: number;
    id_gerente_pdv: number;
    id_usuario_atendimento: number;
    id_status_oportunidade: number;
    id_substatus_oportunidade: number;
    resgate: number;
    convertido_em: string;
    ocultar_dados: number;
    criado_em: string;
    criado_por: number;
    atualizado_em: string;
    ativo: string;
    leads_oportunidade: Array<LeadOportunidadeType> | [];
    data_contabilizacao?: string | null;
    data_venda?: string | null;
    valor_venda?: string | null;
    usuario_pode_editar?: boolean;
    visualizar_historicos_oportunidade_unificado?: boolean;
};

export enum TipoCampoDinamicoAPI {
    EMPREENDIMENTO,
    TIPOLOGIA,
    LOCALIZACAO,
    COMO_CONHECEU,
}

export type EmpreendimentoType = {
    id: number;
    nome: string;
    endereco: string;
    numero: number;
    bairro: string;
    cidade: string;
    uf: string;
    img_destaque_empreendimento: string;
    usar_logo_pdf_propria: string;
    cep: number;
    contratada?: string;
    id_empresa?: string;
    id_regional?: string;
    descricao?: string;
    atrativos?: [];
};

export type EmpreendimentoFotoType = {
    id: number,
    id_empreendimento: number,
    nome: string,
    referencia: string,
    planta_baixa: string,
    endereco: string,
    principal: number,
    background: number,
    link_externo: string,
}

export type TipologiaType = {
    id: number,
    descricao: string,
    sigla: string,
    denominacao: string,
    modelo_associativo: string,
    cadastrado_em: string,
    cadastrado_por: string,
    status: string
}

export type ComoConheceuType = {
    id_como_conheceu: number,
    descricao: string,
}

export type EmpreendimentoVendasType = {
    atrativos: string | null
    atualizado_em: string
    bairro: string | null
    cidade: string | null
    descricao: string | null
    exibir_portal_corretor: string
    id: string
    modulos: string
    nome: string
    previsao_entrega: string | null
    uf: string | null
    workflow_ativo: string
    vinculo_prospecta_vende: string
    ativo: string
}