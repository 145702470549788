import React, { createContext, Dispatch, ReactElement, useState } from "react";
import Swal from "sweetalert2";
import { QuestionarioType, RespondidoType } from "../../components/types/questionario";
import { QuestionarioService } from "../../services/QuestionarioService";
import { ComoConheceuType, EmpreendimentoFotoType, EmpreendimentoType, EmpreendimentoVendasType, TipologiaType } from "../../components/types/oportunidadeTypes";
import { trackPromise } from "react-promise-tracker";

interface QuestionarioContextProps {
  hash: string;
  questionario: QuestionarioType;
  dataUltimaResposta: RespondidoType;
  setDataUltimaResposta: Dispatch<React.SetStateAction<RespondidoType>>;
  empreendimento: EmpreendimentoType;
  fotosEmpreendimento: EmpreendimentoFotoType[];
  tipologias: TipologiaType[];
  localidades: string[];
  comoConheceu: ComoConheceuType[];
  empreendimentos: EmpreendimentoVendasType[];
  buscarDadosQuestionario: (hash: string) => void;
  linkExpirado: boolean;
  oportunidade: number;
  idQuestionarioPublico?: number;
  setLinkExpirado: React.Dispatch<React.SetStateAction<boolean>>;
  setQuestionario: React.Dispatch<React.SetStateAction<QuestionarioType>>;
}

export const QuestionarioContext = createContext({} as QuestionarioContextProps);

export function QuestionarioProvider({ children }: { children: ReactElement }) {
  const [hash, setHash] = useState("");
  const [idQuestionarioPublico, setIdQuestionarioPublico] = useState<number>();
  const [questionario, setQuestionario] = useState<QuestionarioType>({} as QuestionarioType);
  const [dataUltimaResposta, setDataUltimaResposta] = useState<RespondidoType>({} as RespondidoType);
  const [empreendimento, setEmpreendimento] = useState<EmpreendimentoType>({} as EmpreendimentoType);
  const [fotosEmpreendimento, setFotosEmpreendimento] = useState<EmpreendimentoFotoType[]>([]);
  const [tipologias, setTipologias] = useState<TipologiaType[]>([]);
  const [localidades, setLocalidades] = useState<string[]>([]);
  const [comoConheceu, setComoConheceu] = useState<ComoConheceuType[]>([]);
  const [empreendimentos, setEmpreendimentos] = useState<EmpreendimentoVendasType[]>([]);
  const [linkExpirado, setLinkExpirado] = useState(false);
  const [oportunidade, setOportunidade] = useState(0);

  const buscarDadosQuestionario = (hash: string) => {
    trackPromise(QuestionarioService.buscarDadosQuestionario(hash)).then((response: any) => {
      setHash(hash);
      setIdQuestionarioPublico(response?.data?.id_questionario_publico);
      setQuestionario(response?.data?.questionario);
      setDataUltimaResposta({
        respondido: response?.data?.ja_respondido,
        dataResposta: response?.data?.data_ultima_resposta
      });
      setEmpreendimento(response?.data?.empreendimento);
      setFotosEmpreendimento(response?.data?.fotos_empreendimento);
      setTipologias(response?.data?.tipologias);
      setLocalidades(response?.data?.localidades);
      setComoConheceu(response?.data?.como_conheceu);
      setEmpreendimentos(response?.data?.empreendimentos);
      setOportunidade(response?.data?.oportunidade);

    }).catch((error) => {
      setHash(hash);
      if(error.response.status === 401){
        setLinkExpirado(true);
      }else{
        Swal.fire(
          'Ooops... Ocorreu um erro ao carregar dados do questionário.',
          '',
          'error'
        )
      }
    })
  }

  return (
    <QuestionarioContext.Provider value={{
      hash,
      questionario,
      buscarDadosQuestionario,
      dataUltimaResposta,
      setDataUltimaResposta,
      empreendimento,
      empreendimentos,
      fotosEmpreendimento,
      tipologias,
      localidades,
      comoConheceu,
      linkExpirado,
      setLinkExpirado,
      oportunidade,
      idQuestionarioPublico,
      setQuestionario,
    }}>
      {children}
    </QuestionarioContext.Provider>
  );
}
