import { Route, Routes, useParams } from "react-router-dom";
import { QuestionarioPage } from "./pages/QuestionarioPage";

export const Routers = () => {

    return (
        <Routes>
            <Route path="/:token" element={<QuestionarioPage />} />
        </Routes>
    )
}