import React, { useContext } from "react";
import "./index.css";
import warning from "../../assets/images/warning.png";
import { QuestionarioContext } from "../../context/QuestionarioContext/QuestionarioContext";
import moment from "moment";

export function MensagemJaRespondida() {
    const { dataUltimaResposta } = useContext(QuestionarioContext)
    return (
        <div className="mensagem-ja-respondida-section">
            <img src={warning} alt="warning" />
            <div className="mensagem">
                <span>Questionário já respondido !!!</span>
                <span>{dataUltimaResposta?.dataResposta && dataUltimaResposta?.dataResposta.trim() ? moment(dataUltimaResposta?.dataResposta).format("DD/MM/YYYY") : ""}</span>
            </div>
        </div>
    )
}