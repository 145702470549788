import React, { useContext, useEffect } from "react";
import "./index.css";
import { FormProvider, useForm } from "react-hook-form";
import { Alert, Button, Form, Row } from "react-bootstrap";
import { QuestionarioContext } from "../../context/QuestionarioContext/QuestionarioContext";
import { TipoRespostaEnum } from "../types/questionario";
import { apenasNumeros } from "../../helpers/Mascaras";
import { PerguntaItem } from "./PerguntaItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { trackPromise } from "react-promise-tracker";
import { QuestionarioService } from "../../services/QuestionarioService";
import Swal from "sweetalert2";

export function Questionario() {
  const { hash, questionario, setDataUltimaResposta, setLinkExpirado } = useContext(QuestionarioContext);
  const metodos = useForm();

  const formatarResposta = (data: any) => {
    Object.keys(data).map((key) => {
      let pergunta = questionario.perguntas?.find(
        (pergunta) => pergunta.id_pergunta === parseInt(key)
      );
      switch (pergunta?.pergunta.id_tipo_resposta_pergunta) {
        case TipoRespostaEnum.CEP:
        case TipoRespostaEnum.CPF:
        case TipoRespostaEnum.TELEFONE:
        case TipoRespostaEnum.MOEDA:
          data[key] = apenasNumeros(data[key]);
          break;
        default:
          break;
      }
    });
    return data;
  };

  const handleOnSubmit = (data: any) => {
    let respostasFormatadas = formatarResposta(data);
    trackPromise(
      QuestionarioService.storeRespostasQuestionario(hash, respostasFormatadas))
      .then((response: any) => {
        setDataUltimaResposta({
          respondido: true
        })
      })
      .catch((error) => {
        if (typeof error?.response?.data === 'string') {
          if (error?.response?.data.includes("Formulário já respondido")) {
            setDataUltimaResposta({
              respondido: true,
              dataResposta: " "
            }
            )
          } else if (error?.response?.data.includes("Formulário expirado")) {
            setLinkExpirado(true);
          }
          Swal.fire({
            titleText: "Ooops...",
            text: error?.response?.data ? error?.response?.data : "Ocorreu um erro ao tentar salvar as respostas: " + error,
            icon: "error",
          });
        } else {
          Swal.fire({
            titleText: "Ooops...",
            text: error?.response?.data?.message ?? ("Ocorreu um erro ao tentar salvar as respostas: " + error),
            icon: "error",
          });

          if(error?.response?.data?.message.includes("Expirado")){
            setLinkExpirado(true);
          }
        }
      });
  };

  useEffect(() => {
    console.log(questionario);

  }, [questionario])


  return (
    <FormProvider {...metodos}>
      <Form
        onSubmit={metodos.handleSubmit(handleOnSubmit)}
        className="form-questionario"
      >
        {questionario?.perguntas?.length ? (
          <>
            {questionario?.perguntas?.map((pergunta, index) => {
              return (
                <PerguntaItem key={index} index={index} pergunta={pergunta} />
              );
            })}
            <Row className="btn-enviar-questionario">
              <Button variant="success" type="submit">
                Enviar Resposta
                <FontAwesomeIcon icon={faPaperPlane} />
              </Button>
            </Row>
          </>
        ) : (
          <Alert variant="warning">
            Nenhuma pergunta vinculada nesse questionário
          </Alert>
        )}
      </Form>
    </FormProvider>
  );
}
