import React, { useContext, useMemo } from "react";
import "./index.css"
import { QuestionarioContext } from "../../context/QuestionarioContext/QuestionarioContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
export function Header() {
    const { empreendimento, fotosEmpreendimento } = useContext(QuestionarioContext);

    const fotoBackground = useMemo(() => {
        return fotosEmpreendimento?.find((foto) => {
            return foto.background === 1;
        })
    }, [fotosEmpreendimento]);

    const fotoPrincipal = useMemo(() => {
        return fotosEmpreendimento?.find((foto) => {
            return foto.principal === 1;
        })
    }, [fotosEmpreendimento]);



    return (
        fotoBackground ?
            <div className="header-section"
                style={{
                    backgroundImage: `url(${fotoBackground?.endereco})`
                }}
            >
                <div className="empreendimento-section">
                    <img className="logo-empreendimento" src={fotoPrincipal?.endereco} alt="logo_empreendimento" />
                    <span className="nome-empreendimento">{empreendimento.nome}</span>
                </div>
            </div>
            :
            <div>
                <div className="empreendimento-sem-foto-section">
                    <div className="empreendimento-sem-foto">
                        <FontAwesomeIcon icon={faBuilding} />
                    </div>
                    <span className="nome-empreendimento">{empreendimento.nome}</span>
                </div>
            </div>
    )
}