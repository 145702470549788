import React from "react";
import "./index.css";

export function Footer() {
    return (
        <footer>
            <span>© Copyright <a href="https://dommus.com.br/" target="_blank" rel="noreferrer">Dommus Tecnologia</a>. </span>
            <span>Todos os Direitos Reservados</span>
        </footer>
    )
}