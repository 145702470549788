import React, { useContext, useEffect } from "react";
import "./index.css";
import { Header } from "../../components/Header";
import { Questionario } from "../../components/Questionario";
import { MensagemRespondida } from "../../components/MensagemRespondida";
import { QuestionarioContext } from "../../context/QuestionarioContext/QuestionarioContext";
import { useParams } from "react-router-dom";
import { LinkExpirado } from "../LinkExpirado";
import { Footer } from "../../components/Footer";
import DommusLoading from "../../components/DommusLoading/DommusLoading";
import { MensagemJaRespondida } from "../../components/MensagemJaRespondida";


export function QuestionarioPage() {
    const params = useParams();
    const { linkExpirado, hash, buscarDadosQuestionario, questionario, dataUltimaResposta } = useContext(QuestionarioContext);

    useEffect(() => {
        if (params?.token && !hash) {
            buscarDadosQuestionario(params.token)
        }
    }, [hash]);

    return (
        <>
            {hash ?
                <>
                    {linkExpirado ?
                        <LinkExpirado />
                        :
                        <>
                            <Header />
                            <div className="questionario-section">
                                <div className="title-section">
                                    <span className="title">{questionario?.descricao ?? ""}</span>
                                    <p className="mensagem">{questionario?.mensagem ?? ""}</p>
                                </div>
                                <div className="body-section">
                                    {dataUltimaResposta?.respondido ?
                                        dataUltimaResposta?.dataResposta ?
                                            <MensagemJaRespondida />
                                            :
                                            <MensagemRespondida />
                                        :
                                        <Questionario />
                                    }
                                </div>
                            </div>
                        </>}
                    <Footer />
                </>
                :
                <DommusLoading />
            }
        </>
    )
}